const mapNumeralLocale = function(locale) {
  switch(locale) {
    case 'de-DE':
      return 'de';
    case 'de-AT':
      return 'de-at';
    case 'de-CH':
      return 'de-ch';
    case 'en-US':
      return 'en-gb';
    case 'en-GB':
      return 'en-gb';
    case 'it-IT':
      return 'it';
    case 'fr-FR':
      return 'fr-fr';
    case 'fr-BE':
      return 'fr-be';
    case 'fr-LU':
      return 'fr-lu';
    case 'es-ES':
      return 'es-es';
    case 'pl-PL':
      return 'pl';
    case 'nl-NL':
      return 'nl-nl';
    case 'bg-BG':
      return 'bg';
    case 'bs-BA':
      return 'bs-ba';
    case 'zh-CN':
      return 'chs';
    case 'da-DK':
      return 'da-dk';
    case 'et-EE':
      return 'et';
    case 'fi-FI':
      return 'fi';
    case 'el-GR':
      return 'el-gr';
    case 'el-CY':
      return 'el-cy';
    case 'he-IL':
      return 'he-il';
    case 'ga-IE':
      return 'ga-ie';
    case 'ja-JP':
      return 'ja';
    case 'hr-HR':
      return 'hr-hr';
    case 'lv-LV':
      return 'lv';
    case 'lt-LT':
      return 'lt-lt';
    case 'mt-MT':
      return 'mt-mt';
    case 'pt-PT':
      return 'pt-pt';
    case 'ro-RO':
      return 'ro-ro';
    case 'ru-RU':
      return 'ru';
    case 'sl-SI':
      return 'sl';
    case 'sv-SE':
      return 'sv-se';
    case 'sk-SK':
      return 'sk';
    case 'cs-CZ':
      return 'cs';
    case 'tr-CY':
      return 'tr-cy';
    case 'tr-TR':
      return 'tr-tr';
    case 'hu-HU':
      return 'hu';
    case 'ar-AE':
      return 'ar-ae';
    default:
      return 'en-gb';
  }
};

export default mapNumeralLocale;


