const log = function (err) {
    try {
        if (this.$store.state.environment.debug === true) {
            // eslint-disable-next-line no-console
            console.log(err);
        }
    } catch (e) {
        return;
    }
};

export default log;