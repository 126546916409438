const mapDayJSLocale = function(locale) {
  switch(locale) {
    case 'de-DE':
      return 'de';
    case 'de-AT':
      return 'de-at';
    case 'de-CH':
      return 'de-ch';
    case 'en-US':
      return 'en';
    case 'en-GB':
      return 'en-gb';
    case 'it-IT':
      return 'it';
    case 'fr-FR':
      return 'fr';
    case 'fr-BE':
      return 'fr';
    case 'fr-LU':
      return 'fr';
    case 'es-ES':
      return 'es';
    case 'pl-PL':
      return 'pl';
    case 'nl-NL':
      return 'nl';
    case 'bg-BG':
      return 'bg';
    case 'bs-BA':
      return 'bs';
    case 'zh-CN':
      return 'zh-cn';
    case 'da-DK':
      return 'da';
    case 'et-EE':
      return 'et';
    case 'fi-FI':
      return 'fi';
    case 'el-GR':
      return 'el';
    case 'el-CY':
      return 'el';
    case 'he-IL':
      return 'he';
    case 'ga-IE':
      return 'ga';
    case 'ja-JP':
      return 'ja';
    case 'hr-HR':
      return 'hr';
    case 'lv-LV':
      return 'lv';
    case 'lt-LT':
      return 'lt';
    case 'mt-MT':
      return 'mt';
    case 'pt-PT':
      return 'pt';
    case 'ro-RO':
      return 'ro';
    case 'ru-RU':
      return 'ru';
    case 'sl-SI':
      return 'sl';
    case 'sv-SE':
      return 'sv';
    case 'sk-SK':
      return 'sk';
    case 'cs-CZ':
      return 'cs';
    case 'tr-CY':
      return 'tr';
    case 'tr-TR':
      return 'tr';
    case 'hu-HU':
      return 'hu';
    case 'ar-AE':
      return 'ar';
    default:
      return 'en';
  }
};

export default mapDayJSLocale;


