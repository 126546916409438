const trimNumber = function(number) {
  return number.toString()
    .replaceAll(' ','')
    .replaceAll('-','')
    .replaceAll('(','')
    .replaceAll(')','')
    .replaceAll('/', '');
};

export default trimNumber;
