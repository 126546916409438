<script setup>
import InputBasis from "./InputBasis.vue";
</script>
<script>
  export default {
    name: "DpInputHouseNumber",
    data() {
      return {
        translationReportAlias: {
          label: this.$t('input.label'),
          placeholder: this.$t('input.placeholder'),
          tooltip: this.$t('input.tooltip'),
          description: this.$t('input.description')
        }
      };
    },
    methods: {
      validate(rule, value, callback) {
        if (this.required !== true) return callback();
        if (value === '') {
          callback(new Error(this.$t('inputValidation.emptyField.message')));
        } else if (!/[0-9A-Za-z]+/.test(value)) {
          callback(new Error(this.$t('inputValidation.regEx.message')));
        } else {
          callback();
        }
      }
    }
  }
</script>

<template>
  <InputBasis :rules="[{ validator: validate, trigger: ['blur', 'change'] }]" />
</template>

<i18n>
  {
  }
</i18n>

