export default {
  data() {
    return {
      _screenSize_event: null,
      _screenSize_width: null,
      _screenSize_height: null,
      _screenSize_isMobile: undefined
    };
  },
  computed: {
    screenSizeEvent() {
      return this.$data._screenSize_event;
    },
    screenSizeWidth() {
      return this.$data._screenSize_width;
    },
    screenSizeHeight() {
      return this.$data._screenSize_height;
    },
    screenSizeIsMobile() {
      return this.$data._screenSize_isMobile;
    },
    maxMobileScreenSize() {
      return 767; // Element UI break-point at 768px
    }
  },
  methods: {
    $screenSizeGetWidth() {
      return window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
    },
    $screenSizeGetHeight() {
      return window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;
    },
    $screenSizeGetIsMobile() {
      return (this.$data._screenSize_width <= this.maxMobileScreenSize);
    },
    $screenSizeHandleResize(event) {
      this.$data._screenSize_event = event;
      this.$data._screenSize_width = this.$screenSizeGetWidth();
      this.$data._screenSize_height = this.$screenSizeGetHeight();
      this.$data._screenSize_isMobile = this.$screenSizeGetIsMobile();
    },
    $screenSizeDestroyListener() {
      window.removeEventListener('resize', this.$screenSizeHandleResize);
    },
    $screenSizeAddListener() {
      this.$screenSizeHandleResize();
      window.addEventListener('resize', this.$screenSizeHandleResize);
    }
  },
  mounted() {
    this.$screenSizeAddListener();
  },
  destroyed() {
    this.$screenSizeDestroyListener();
  }
}
