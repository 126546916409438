import each from 'lodash/each';
import startsWith from 'lodash/startsWith';
import trimNumber from "./trimNumber";

const hasValidCountryCallingCode = function(jurisdictionsList, number) {
  number = trimNumber(number);
  let hasValidCountryCallingCode = false;
  each(jurisdictionsList, (jurisdiction) => {
    if (startsWith(number, jurisdiction.callingCode)) {
      hasValidCountryCallingCode = true;
    }
  });
  return hasValidCountryCallingCode;
}

export default hasValidCountryCallingCode;
