import {ref, onMounted, onUnmounted, onBeforeMount} from 'vue';

export function useViewportSize() {
    const maxMobileViewportWidth = 767; // Breakpoint at 768px

    const viewportWidth = ref(0);
    const viewportHeight = ref(0);
    const isMobile = ref(false);

    function getViewportSize() {
        viewportWidth.value = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;
        viewportHeight.value = window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight;
    }

    function isViewportWidthMobile() {
        isMobile.value = (viewportWidth.value <= maxMobileViewportWidth);
    }

    function viewportResizeHandler() {
        getViewportSize();
        isViewportWidthMobile();
    }

    onBeforeMount(() => {
        viewportResizeHandler()
    })
    onMounted(() => window.addEventListener('resize', viewportResizeHandler));
    onUnmounted(() => window.removeEventListener('resize', viewportResizeHandler));

    return { isMobile, viewportWidth, viewportHeight }
}