<script setup>
import InputBasis from "./InputBasis.vue";
</script>
<script>
  export default {
    name: "DpInputBIC",
    data() {
      return {
        translationReportAlias: {
          label: this.$t('input.label'),
          placeholder: this.$t('input.placeholder'),
          tooltip: this.$t('input.tooltip'),
          description: this.$t('input.description')
        }
      };
    },
    methods: {
      validate(rule, value, callback) {
        if (this.required !== true) return callback();
        if (value === '') {
          callback(new Error(this.$t('inputValidation.emptyField.message')));
        } else if (!/[.]*/.test(value)) {
          callback(new Error(this.$t('inputValidation.regEx.message')));
        } else {
          callback();
        }
      }
    }
  }
</script>

<template>
  <InputBasis
      :rules="[
        { validator: validate, trigger: ['blur', 'change'] }
    ]"
      :input-parameters="{
      'maxlength': 30,
      'minlength': 10,
      'class': 'dp-input__bic'
    }"
  />
</template>

<style lang="scss">
  .dp-input__bic {
    text-transform: uppercase;

    ::-webkit-input-placeholder { /* WebKit browsers */
      text-transform: none;
    }

    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      text-transform: none;
    }

    ::-moz-placeholder { /* Mozilla Firefox 19+ */
      text-transform: none;
    }

    :-ms-input-placeholder { /* Internet Explorer 10+ */
      text-transform: none;
    }

    ::placeholder { /* Recent browsers */
      text-transform: none;
    }

  }

</style>

<i18n>
  {
  }
</i18n>

