const mapSpCountryToFlag = function(spCountry) {
  switch (spCountry) {
    case "5b4c44d1-4455-4cf0-8081-5a5e95e74ceb":
      return "ie";
    case "3f6f9e0a-0656-4f52-a0c4-c63dc42a7bec":
      return "de";
    case "85831122-1ffa-4917-b0f8-fc4362782196":
      return "sm";
    case "3cc51da5-ecbb-40cb-aadc-a3d9fe6d7cd0":
      return "pl";
    case "0d19a763-641b-4973-b54a-a27e3f3586e0":
      return "dk";
    case "72fb9bd4-83c9-485d-858e-5692e93608c7":
      return "it";
    case "c95d9732-a3dc-49fb-a1a1-bf89210c93d7":
      return "cz";
    case "8124eabe-cb2c-4d83-96fa-4d0396ba34d7":
      return "cy";
    case "a13eadfe-3e65-4fa2-b866-34e7af6516fb":
      return "be";
    case "eb55bf85-2567-4a4f-aed9-5e6ad7bc721b":
      return "at";
    case "2d1b50e2-f038-403e-84fb-cf4fbed8767d":
      return "es";
    case "7203f298-d139-4078-af80-2005dc4a5be0":
      return "fi";
    case "e66048a3-9504-47b3-9b57-8af1181b76a3":
      return "nl";
    case "4af836d6-cb65-4b76-a387-02722ee14840":
      return "lt";
    case "47b13c8f-e495-4364-8ff8-c9ee87ff0294":
      return "lv";
    case "c758cfa7-4de4-484d-9c21-f07e5f6a0d00":
      return "gr";
    case "f54da6da-f4e6-4558-9934-c32cb223a9bf":
      return "hr";
    case "f39d1e71-eaf9-4bf9-9f65-a18ce13e5b1d":
      return "si";
    case "1b3588b5-a652-49c4-a2d8-841407400bc3":
      return "bg";
    case "1c1e8d50-2009-4786-b8a6-01b509799743":
      return "ro";
    case "d4f14628-c287-45dd-8dbb-93541897005e":
      return "se";
    case "05195403-7489-4093-8c26-7e6900c38b81":
      return "fr";
    case "1e064216-3f5a-45ca-b9e6-5825f7c453f0":
      return "lu";
    case "31f3fcc8-e36c-4945-998b-b955da9c765c":
      return "gb";
    case "eb47d6c9-0333-47d4-b7a9-fc8bec3a50e1":
      return "ch";
    case "4ecdb247-28ff-45e2-bab3-0269fbdb3191":
      return "ee";
    case "500d500d-7a87-4495-8600-bb707c50da71":
      return "pt";
    case "6193f349-de44-4818-886b-152d305d5942":
      return "mt";
    case "e7cb5b5c-d118-4d86-8831-2045cd9e8fd6":
      return "hu";
    case "e583d191-a686-49ed-bc5a-0a59251cc84f":
      return "sk";
    default:
      return "";
  }
};

export default mapSpCountryToFlag;
