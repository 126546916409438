import dayjs from 'dayjs'
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

export function useDateFormat(dateFormat) {

    function localDate(date) {
        return dayjs.utc(date).local().format(dateFormat);
    }

    return { localDate }
}