<script setup>
import InputBasis from "./InputBasis.vue";
</script>
<script>
  export default {
    name: "DpInputWebsite",
    data() {
      return {
        translationReportAlias: {
          label: this.$t('input.label'),
          placeholder: this.$t('input.placeholder'),
          tooltip: this.$t('input.tooltip'),
          description: this.$t('input.description')
        }
      };
    }
  }
</script>

<template>
  <InputBasis />
</template>

<i18n>
  {
  }
</i18n>
