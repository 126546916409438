import trimNumber from "./trimNumber";

const isValidNumber = function(number) {
  let trimmedNumber = trimNumber(number).replace('+', '');
  if (trimmedNumber[0] === '0') {
    return false;
  }
  return /\d/g.test(trimmedNumber)
}

export default isValidNumber;
